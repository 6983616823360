<template>
  <div class="projects-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $vuetify.lang.t('$vuetify.different.projects') }}
            </v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                placeholder="Search"
                v-model="search"
                hide-details
              ></v-text-field>
              <v-btn small rounded @click="init" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="editItem(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.delete')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="showUsers(item)"
                      >
                        <v-icon>mdi-account-multiple</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.user.users')
                      }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.api_enabled="{ item }">
                  <v-simple-checkbox
                    v-model="item.api_enabled"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.logo="{ item }">
                  <v-avatar class="ma-1" size="48">
                    <img
                      alt="Logo"
                      :src="item.logo"
                    />
                  </v-avatar>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="itemForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="item.platform_id"
                    :items="compPlatforms"
                    :label="$vuetify.lang.t('$vuetify.different.platform') + '*'"
                    :rules="[rules.required]"
                    required
                    item-value="id"
                    item-text="title"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="item.name"
                    :label="$vuetify.lang.t('$vuetify.different.title') + '*'"
                    :rules="[rules.required, rules.max191]"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="9">
                  <v-text-field
                    v-model="item.order_payment_comment"
                    :label="$vuetify.lang.t('$vuetify.different.comment')"
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-checkbox
                    v-model="item.api_enabled"
                    label="API"
                  ></v-checkbox>
                </v-col>
                <v-col
                  v-if="item.logo"
                  align-self="start"
                  class="pa-0"
                  cols="12" sm="6" md="3"
                >
                  <v-avatar
                    class="profile"
                    color="grey"
                    size="164"
                    tile
                  >
                    <v-img :src="item.logo"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" sm="6" :md="item.logo ? 9 : 12">
                  <v-file-input
                    counter
                    show-size
                    small-chips
                    accept="image/png, image/jpeg, image/bmp"
                    :label="$vuetify.lang.t('$vuetify.different.logo')+ item.id ? '' : '*'"
                    ref="itemFile"
                    v-model="itemFile"
                    :rules="item.id ? [] : [rules.required]"
                  ></v-file-input>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.api_attributes.url"
                    label="URL*"
                    :rules="[rules.required, rules.max191]"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.api_attributes.user"
                    :label="$vuetify.lang.t('$vuetify.user.user') + '*'"
                    :rules="[rules.required, rules.max191]"
                    required
                    counter
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.api_attributes.key"
                    :label="$vuetify.lang.t('$vuetify.login.key') + '*'"
                    :rules="[rules.required]"
                    required
                    counter
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="!valid"
            :loading="itemLoading"
          >
            {{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{ $vuetify.lang.t('$vuetify.different.deleteItemConfirm') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="itemLoading"
          >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUsers"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogUsers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.different.project') + ' - ' + item.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
          :headers="compUsersHeaders"
          :items="users"
          fixed-header
          class="elevation-1"
          item-key="id"
          :loading="usersLoading"
          :options.sync="usersOptions"
          :server-items-length="totalUsersItems"
        >
          <template v-slot:item.avatar="{ item }">
            <v-avatar class="ma-1">
              <img
                alt="Avatar"
                :src="item.avatar ? item.avatar : 'static/img/av.png'"
              />
            </v-avatar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-checkbox
              v-model="item.project_allowed"
              color="primary"
              @click="toggleUser(item)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogUsers: false,
      valid: true,
      itemLoading: false,
      usersLoading: false,
      tableLoading: false,
      search: null,
      awaitingSearch: false,
      item: {
        id: null,
        platform_id: null,
        name: null,
        logo: null,
        order_payment_comment: null,
        api_enabled: null,
        created_at: null,
        updated_at: null,
        api_attributes: {
          id: null,
          project_id: null,
          url: null,
          user: null,
          key: null,
          created_at: null,
          updated_at: null,
        }
      },
      emptyItem: {
        id: null,
        platform_id: null,
        name: null,
        logo: null,
        order_payment_comment: null,
        api_enabled: null,
        created_at: null,
        updated_at: null,
        api_attributes: {
          id: null,
          project_id: null,
          url: null,
          user: null,
          key: null,
          created_at: null,
          updated_at: null,
        }
      },
      itemFile: null,
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        max191: (v) =>
          (v && v.length <= 191) || this.$vuetify.lang.t('$vuetify.validation.max191')
      },
      totalItems: 0,
      totalUsersItems: 0,
      options: {},
      usersOptions: {},
      items: [],
      users: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.projects')
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
        this.itemFile = null
      }
    },
    dialogUsers: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    dialogDelete: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
      }
    },
    search(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.init()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    options: {
      handler() {
        this.init()
      },
      deep: true
    },
    usersOptions: {
      handler() {
        if (this.item.id)
          this.getUsers()
      },
      deep: true
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    formTitle() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.different.editItem')
        : this.$vuetify.lang.t('$vuetify.different.newItem')
    },
    compAction() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    },
    compHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {
          text: this.$vuetify.lang.t('$vuetify.different.logo'),
          value: 'logo'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.project'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.comment'),
          value: 'order_payment_comment'
        },
        {
          text: 'API',
          value: 'api_enabled'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.updatedAt'),
          value: 'updated_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compPlatforms() {
      let arr = this.$store.getters.getPlatforms
      let modified = []
      if (arr) {
        arr.forEach(function (entry) {
          modified.push({
            id: entry.id,
            title: entry.name
          })
        })
      }
      return modified
    },
    compUsersHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {text: this.$vuetify.lang.t('$vuetify.different.photo'), value: 'avatar', sortable: false},
        {text: this.$vuetify.lang.t('$vuetify.user.name'), value: 'name'},
        {text: this.$vuetify.lang.t('$vuetify.user.surname'), value: 'surname'},
        {text: this.$vuetify.lang.t('$vuetify.login.email'), value: 'email'},
        {text: this.$vuetify.lang.t('$vuetify.dates.createdAt'), value: 'created_at'},
        {text: '', value: 'action', sortable: false}
      ]
    }
  },
  mounted() {
  },
  methods: {
    editItem(item) {
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.item = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.itemLoading = true

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/project/destroy',
          {id: this.item.id},
          this.compAuthHeader
        )
        .then((response) => {
          this.itemLoading = false
          if (response.status === 200) {
            this.init()
            this.dialogDelete = false

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.deleted')
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch(() => {
          this.itemLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    save() {
      let t = this
      if (this.$refs.itemForm.validate()) {
        this.itemLoading = true

        let formData = new FormData()
        //add file
        if (this.itemFile) formData.append('file', this.itemFile)

        // Item
        for (let key in this.item) {
          if (this.item[key]) {
            if (typeof this.item[key] === 'object') {
              for (let sub_key in this.item[key]) {
                if (this.item[key][sub_key] && sub_key !== 'id' && sub_key !== 'api_enabled')
                  formData.append(sub_key, this.item[key][sub_key])
              }
            } else formData.append(key, this.item[key])
          }
        }

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.$axios
          .post(
            this.item.id ? this.$store.getters.getApiUrl + 'api/project/update' : this.$store.getters.getApiUrl + 'api/project',
            formData,
            headers
          )
          .then((response) => {
            this.itemLoading = false
            if (response.status === 200) {
              this.init()
              this.dialog = false
              this.itemFile = null

              this.snackbar.show = true
              this.snackbar.color = 'green'
              this.snackbar.text = this.$vuetify.lang.t(
                '$vuetify.messages.saved'
              )
            } else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch(() => {
            this.itemLoading = false
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          })
      }
    },
    init() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy ? this.options.sortBy[0] : null,
        descending: this.options.sortDesc ? this.options.sortDesc[0] : null
      }
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/projects',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }

          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    showUsers(item) {
      this.item = item
      this.dialogUsers = true
      this.getUsers()
    },
    getUsers() {
      this.usersLoading = true
      let data = {
        id: this.item.id,
        page: this.usersOptions.page,
        per_page: this.usersOptions.itemsPerPage,
        sort_by: this.usersOptions.sortBy ? this.usersOptions.sortBy[0] : null,
        descending: this.usersOptions.sortDesc ? this.usersOptions.sortDesc[0] : null
      }
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/project/users',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.data
            this.totalUsersItems = response.data.meta.total
          }

          this.usersLoading = false
        })
        .catch(() => {
          this.usersLoading = false
        })

    },
    toggleUser(item) {
      let data = {
        project_id: this.item.id,
        user_id: item.id
      }
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/project/user',
          data,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.saved')
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    }
  }
}
</script>
